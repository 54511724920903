@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700");
@import url("//fonts.googleapis.com/earlyaccess/notosanskr.css");

/* Custom Fonts - Start */
@font-face {
	font-family: "NotoSans-L";
	src: url("./fonts/notosans/NotoSans-Light.eot");
	src: url("./fonts/notosans/NotoSans-Light.eot") format("embedded-opentype"), url("./fonts/notosans/NotoSans-Light.woff") format("woff"),
		url("./fonts/notosans/NotoSans-Light.otf") format("truetype");
}

@font-face {
	font-family: "NotoSans-R";
	src: url("./fonts/notosans/NotoSans-Regular.eot");
	src: url("./fonts/notosans/NotoSans-Regular.eot") format("embedded-opentype"), url("./fonts/notosans/NotoSans-Regular.woff") format("woff"),
		url("./fonts/notosans/NotoSans-Regular.otf") format("truetype");
}

@font-face {
	font-family: "NotoSans-M";
	src: url("./fonts/notosans/NotoSans-Medium.eot");
	src: url("./fonts/notosans/NotoSans-Medium.eot") format("embedded-opentype"), url("./fonts/notosans/NotoSans-Medium.woff") format("woff"),
		url("./fonts/notosans/NotoSans-Medium.otf") format("truetype");
}

@font-face {
	font-family: "NotoSans-B";
	src: url("./fonts/notosans/NotoSans-Bold.eot");
	src: url("./fonts/notosans/NotoSans-Bold.eot") format("embedded-opentype"), url("./fonts/notosans/NotoSans-Bold.woff") format("woff"),
		url("./fonts/notosans/NotoSans-Bold.otf") format("truetype");
}

/* @font-face {
	font-family: "NotoSans-L";
	src: url("./fonts/notosanskr/NotoSansKR-Light.otf") format("truetype");
}

@font-face {
	font-family: "NotoSans-R";
	src: url("./fonts/notosanskr/NotoSansKR-Regular.otf") format("truetype");
}

@font-face {
	font-family: "NotoSans-M";
	src: url("./fonts/notosanskr/NotoSansKR-Medium.otf") format("truetype");
}

@font-face {
	font-family: "NotoSans-B";
	src: url("./fonts/notosanskr/NotoSansKR-Bold.otf") format("truetype");
} */

@font-face {
	font-family: "Glyphicons Halflings";
	src: url("./fonts/glyphicons/glyphicons-halflings-regular.eot");
	src: url("./fonts/glyphicons/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/glyphicons/glyphicons-halflings-regular.woff2") format("woff2"),
		url("./fonts/glyphicons/glyphicons-halflings-regular.woff") format("woff"), url("./fonts/glyphicons/glyphicons-halflings-regular.ttf") format("truetype"),
		url("./fonts/glyphicons/glyphicons-halflings-regular.svg") format("svg");
}

@font-face {
	font-family: "NanumSquareB";
	src: url("./fonts/nanumsquare/NanumSquareB.eot");
	src: url("./fonts/nanumsquare/NanumSquareB.eot") format("embedded-opentype"), url("./fonts/nanumsquare/NanumSquareB.woff") format("woff"),
		url("./fonts/nanumsquare/NanumSquareB.ttf") format("truetype");
}

@font-face {
	font-family: "NanumSquareEB";
	src: url("./fonts/nanumsquare/NanumSquareEB.eot");
	src: url("./fonts/nanumsquare/NanumSquareEB.eot") format("embedded-opentype"), url("./fonts/nanumsquare/NanumSquareEB.woff") format("woff"),
		url("./fonts/nanumsquare/NanumSquareEB.ttf") format("truetype");
}

@font-face {
	font-family: "NanumSquareL";
	src: url("./fonts/nanumsquare/NanumSquareL.eot");
	src: url("./fonts/nanumsquare/NanumSquareL.eot") format("embedded-opentype"), url("./fonts/nanumsquare/NanumSquareL.woff") format("woff"),
		url("./fonts/nanumsquare/NanumSquareL.ttf") format("truetype");
}

@font-face {
	font-family: "NanumSquareR";
	src: url("./fonts/nanumsquare/NanumSquareR.eot");
	src: url("./fonts/nanumsquare/NanumSquareR.eot") format("embedded-opentype"), url("./fonts/nanumsquare/NanumSquareR.woff") format("woff"),
		url("./fonts/nanumsquare/NanumSquareR.ttf") format("truetype");
}

@font-face {
	font-family: "SBAggroB";
	src: url("./fonts/SBAggro/SBAggroB.ttf") format("truetype"), url("./fonts/SBAggro/SBAggroB.otf") format("opentype");
}

@font-face {
	font-family: "SBAggroM";
	src: url("./fonts/SBAggro/SBAggroM.ttf") format("truetype"), url("./fonts/SBAggro/SBAggroM.otf") format("opentype");
}

@font-face {
	font-family: "SBAggroL";
	src: url("./fonts/SBAggro/SBAggroL.ttf") format("truetype"), url("./fonts/SBAggro/SBAggroL.otf") format("opentype");
}

@font-face {
	font-family: "GmarketSansBold";
	src: url("./fonts/gmarketSans/GmarketSansBold.ttf") format("truetype"), url("./fonts/gmarketSans/GmarketSansBold.otf") format("opentype");
}

@font-face {
	font-family: "GmarketSansMedium";
	src: url("./fonts/gmarketSans/GmarketSansMedium.ttf") format("truetype"), url("./fonts/gmarketSans/GmarketSansMedium.otf") format("opentype");
}

@font-face {
	font-family: "GmarketSansLight";
	src: url("./fonts/gmarketSans/GmarketSansLight.ttf") format("truetype"), url("./fonts/gmarketSans/GmarketSansLight.otf") format("opentype");
}

/* Custom Fonts - End */

@font-face {
	font-family: "Glyphicons Halflings";
	src: url("./fonts/glyphicons/glyphicons-halflings-regular.eot");
	src: url("./fonts/glyphicons/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/glyphicons/glyphicons-halflings-regular.woff2") format("woff2"),
		url("./fonts/glyphicons/glyphicons-halflings-regular.woff") format("woff"), url("./fonts/glyphicons/glyphicons-halflings-regular.ttf") format("truetype"),
		url("./fonts/glyphicons/glyphicons-halflings-regular.svg") format("svg");
}
